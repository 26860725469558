// TODO: Create scoped timers so that this component can be used multiple times across one component if it is needed
const now = ref(new Date());
let interval: NodeJS.Timeout | undefined;

/**
 *  Creates a shared timer instance, the timer is automatically stopped on route leave.
 * @returns An object with a stop function to stop the timer, and a ref to the current Date
 */
export function useTimer() {
  onMounted(() => {
    if (!interval)
      interval = setInterval(() => {
        now.value = new Date();
      }, 500);
  });

  /**
   *
   */
  function stop() {
    if (interval) clearInterval(interval);
    interval = undefined;
  }

  onBeforeRouteLeave(stop);

  return { now, stop };
}
